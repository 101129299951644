import * as lunr from "lunr";
import ga from "./ga";

interface Movie {
  title: string;
  date: string;
  original_title?: string;
  id: string;
}

type Cache = Movie[];
type Res = lunr.Index.Result[];

function getSearchIndex(idx: lunr.Index | null): Promise<lunr.Index> {
  if (idx === null) {
    return fetch(`${window.baseUrl}movies/index.json`)
      .then(r => {
        return r.json();
      })
      .then(i => {
        return lunr.Index.load(i);
      });
  } else {
    return Promise.resolve(idx);
  }
}

function search(idx: lunr.Index, query: string): Res | null {
  if (query.length >= 2) {
    const res = idx.search(query);
    ga("send", "event", "Search", "executed", query, res.length);
    return res.slice(0, 10);
  } else {
    return null;
  }
}

function fetchMovies(cache: Cache, res: Res): Promise<Cache> {
  const movies = res.map(r => {
    if (typeof cache[r.ref] !== "object") {
      return fetch(`${window.baseUrl}movies/${r.ref}.json`)
        .then(movie => {
          return movie.json();
        });
    } else {
      return Promise.resolve(cache[r.ref]);
    }
  });
  return Promise.all(movies);
}

function displayResults(cache: Cache, res: Res, query: string, resultElement: Element): string {
  let output = "";
  const moviesOutput = res.map(r => {
    const m: Movie = cache[r.ref];
    const date = (new Date(m.date)).toLocaleDateString();
    const originalTitle = (typeof m.original_title !== "undefined") ? m.original_title : "";
    return `<li><a href="${window.baseUrl}movies/${m.id}.html" title="${originalTitle}">${m.title}</a> (${date})</li>`;
  });
  output += `<ul class="list-unstyled">${moviesOutput.join("")}</ul>`;
  return output;
}

export default function() {
  document.addEventListener("DOMContentLoaded", () => {
    let idx: lunr.Index | null = null;
    const cache: Cache = [];
    const searchInput = document.getElementById("searchInput") as HTMLInputElement;
    const searchForm = document.getElementById("searchForm");
    const searchResults = document.getElementById("searchResults");

    if (searchInput !== null && searchForm !== null && searchResults != null) {
      searchInput.addEventListener("focus", () => {
        getSearchIndex(idx).then(i => idx = i);
      });
      searchForm.addEventListener("submit", e => {
        e.preventDefault();

        const query = searchInput.value;

        getSearchIndex(idx)
          .then(i => {
            idx = i;
            return i;
          })
          .then(i => {
            const res = search(i, query);
            if (res === null) {
              return Promise.reject("Le texte recherché est trop court !");
            } else if (res.length === 0) {
              return Promise.reject(`Aucun résultat pour la recherche <em>${query}</em>`);
            } else {
              return fetchMovies(cache, res)
                .then(c => {
                  c.forEach(m => cache[m.id] = m);
                  return res;
                });
            }
          })
          .then(
            res => searchResults.innerHTML = displayResults(cache, res, query, searchResults),
            err => searchResults.innerHTML = `<p>${err}</p>`,
          );
      });
    }
  });
}
