import "bootstrap-sass/assets/javascripts/bootstrap/collapse";
import "bootstrap-sass/assets/javascripts/bootstrap/transition";

export default function() {
  // Close navbar menu when clicking on a link
  $(document).on("click", ".navbar-collapse.in", e => {
    if ($(e.target).is("a")) {
      $(this).collapse("hide");
    }
  });
}
