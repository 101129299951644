import "jquery-lazyload";

export default function() {
  // Image lazy loading
  $(document).ready(() => {
    $(".lazy").show().lazyload({
      effect : "fadeIn",
      threshold: 600,
    });
  });
}
