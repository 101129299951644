// Global imports for webpack
import "bootstrap-sass/assets/javascripts/bootstrap/carousel";
import "bootstrap-sass/assets/javascripts/bootstrap/scrollspy";
import "../styles/main.scss";

import lazyload from "./lazyload";
import navbar from "./navbar";
import search from "./search";

lazyload();
navbar();
search();
